import React from "react";
import {
	Grid,
	Button,
	Typography
} from "@mui/material";

class Controls extends React.PureComponent {
	render () {
		const {
			orderId,
			onCreateVersion
		} = this.props;

		return (
			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Typography variant="h1">Заказ №{ orderId }</Typography>
				</Grid>
				<Grid item>
					<Grid item>
						<Button variant="contained" onClick={onCreateVersion.bind(this, { isBonusesSkip: false })}>
							Создать заказ
						</Button>
					</Grid>
					<Grid item></Grid>
				</Grid>
			</Grid>
		)
	}
}

export default Controls
