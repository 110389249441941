import React from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	TextField,
	Typography,
	Autocomplete
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Formik
} from "formik";
import {
	DialogConfirmation
} from "../../../../../components"
import * as Yup from "yup";
import ExtraOption from "./ExtraOption";
import agent from "../../../../../agent/agent";

const itemOptions = {
	name: "",
	variants: [],
	status: 1,
	is_multiple: 0,
};
const childrenOption = {
	name: "",
	price_type: 1,
	image_id: null,
	status: true
};

class OptionsForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			apiShopOptionPreset: [],
		}

		this.innerRef = React.createRef();
		this.refDialogConfirmation = React.createRef();
	}

	changeFormMain = ({ target }, index) => {
		const { name, value } = target;

		let newForm = [...this.innerRef.current.values];
		newForm[index][name] = value;

		this.onHaveBeenChanges();
		this.innerRef.current.setValues(newForm);
	}
	changeFormFullExtraOptions = (variants, index) => {
		let newForm = [...this.innerRef.current.values];

		newForm[index].variants = variants;

		this.onHaveBeenChanges();
		this.innerRef.current.setValues(newForm);
	}

	// Логика работы с опциями
	addOption = () => {
		let values = [...this.innerRef.current.values];
		let addItemOptions = JSON.parse(JSON.stringify(itemOptions));
		values.push(addItemOptions);
		this.onHaveBeenChanges();
		this.innerRef.current.setValues(values);
	}
	removeOption = (index, isRemove = false) => {
		if (!isRemove) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: "Вы действительно хотите удалить опцию?",
				acceptButtonAction: this.removeOption.bind(this, index, true)
			})

			return
		}

		let newForm = [...this.innerRef.current.values];
		newForm.splice(index, 1)
		this.onHaveBeenChanges();
		this.innerRef.current.setValues(newForm)
	}

	// Логика работы с элементами опции
	handleChangeOption = (params, optionIndex, optionElementIndex) => {
		let newForm = [...this.innerRef.current.values];
		newForm[optionIndex].variants[optionElementIndex][params.name] = params.value;

		this.onHaveBeenChanges();
		this.innerRef.current.setValues(newForm);
	}
	handleAddOption = (idxExtraOptionOption) => {
		let newForm = [...this.innerRef.current.values];

		let addChildrenOption = JSON.parse(JSON.stringify(childrenOption));

		newForm[idxExtraOptionOption].variants.push(addChildrenOption);
		this.onHaveBeenChanges();
		this.innerRef.current.setValues(newForm);
	}
	handleDeleteOption = (idxExtraOption, optionIndex) => {
		let newForm = [...this.innerRef.current.values];
		newForm[idxExtraOption].variants.splice(optionIndex, 1);
		this.onHaveBeenChanges();
		this.innerRef.current.setValues(newForm);
	}
	handleCopyOption = (idxExtraOption) => {
		let values = [...(this.innerRef.current.values || [])];

		let value = JSON.parse(JSON.stringify({...values[idxExtraOption]}));
		delete value.id;
		value.variants = (value?.variants || []).map((t) => {
			delete t.id;
			return t
		})


		values.push(value);
		this.onHaveBeenChanges();
		this.innerRef.current.setValues(values);
	}

	handleSubmit = async () => {
		await this.innerRef.current.handleSubmit();
	}
	handleGetValues = () => {
		return this.innerRef.current.values;
	}
	handleGetErrors = async () => {
		await this.handleSubmit();
		return this.innerRef.current.errors;
	}

	formikOnSubmit = () => {}

	onHaveBeenChanges = () => {
		this.props.onChangeIsChangedExtraOptions();
	}

	render () {
		const {
			product,
			isActiveTab,
			initialValues,
			onChangeExtraOptionPresets
		} = this.props;

		return (
			<>

				<Box px={2} py={2} bgcolor="white" borderRadius={2} mb={1}>
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item>
							<Typography variant="h3">Настраиваемые варианты</Typography>
						</Grid>
						<Grid item>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									<AutocompleteTemplate
										value={product?.extraOptionPresets || []}
										brandId={product?.brand_id}
										characteristicGroupId={product?.characteristic_group_id}

										onChange={onChangeExtraOptionPresets}
										onSetApiShopOptionPreset={(apiShopOptionPreset) => this.setState({ apiShopOptionPreset })}
									/>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										color="primary"
										size="small"
										onClick={this.addOption}
									>Добавить вариант</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>

				<Formik
					innerRef={this.innerRef}
					initialValues={initialValues}
					validationSchema={validationSchema}

					onSubmit={this.formikOnSubmit}
				>
					{(props) => {
						const {
							values,
							touched,
							errors
						} = props;
						const totalCountOptionPresetRows = (values || []).filter((t) => Boolean(t.option_preset_option_group_id)).length;

						if (!isActiveTab) {
							return null
						}
						return (
							<Box px={2} py={2} bgcolor="white" borderRadius={2}>
								<Grid container spacing={4}>
									{values.map((extraOption, idxExtraOption) => {
										const isOptionPresetGroup = Boolean(extraOption.option_preset_option_group_id);
										return (
											<Grid item xs={12} key={`extra-option-${idxExtraOption}`}>
												<ExtraOption
													extraOption={JSON.stringify(extraOption)}
													idxExtraOption={idxExtraOption}
													delayedRenderTime={200 * (idxExtraOption + 1)}
													totalCountOptionPresetRows={totalCountOptionPresetRows}

													isDisabledEdit={isOptionPresetGroup}
													isOptionPresetGroup={isOptionPresetGroup}
													isDisabledEditPrice={isOptionPresetGroup}

													touched={touched}
													errors={errors}

													changeFormMain={this.changeFormMain}
													removeOption={this.removeOption}
													handleAddOption={this.handleAddOption}
													handleChangeOption={this.handleChangeOption}
													handleDeleteOption={this.handleDeleteOption}
													handleCopyOption={this.handleCopyOption}
													changeFormFullExtraOptions={this.changeFormFullExtraOptions}
												/>
											</Grid>
										)
									})}
								</Grid>
							</Box>
						)
					}}
				</Formik>


				<DialogConfirmation
					ref={this.refDialogConfirmation}
				/>

			</>
		)
	}
}
const AutocompleteTemplate = React.memo((props) => {
	const {
		value: initValues,
		brandId,
		characteristicGroupId,

		onChange,
		onSetApiShopOptionPreset
	} = props;
	const [value, setValues] = React.useState([]);
	const [options, setOptions] = React.useState([]);
	const [isLoading, setLoading] = React.useState(true);
	const refDialogConfirmation = React.createRef();
	const classes = useStyles();

	React.useEffect(() => {
		(async () => {
			await getOptions();
		})();
	}, []);

	const getOptions = async () => {
		let search = [
			'ngrestCallType=list',
			'expand=extraProductsCount',
			'fields=id,id,created_at,updated_at,name,slug,description,extraProductsCount',
			'sort=-id',
			'page=1',
		];
		if (typeof brandId !== undefined) {
			search.push(`filter[brand_id]=${brandId}`);
		}
		if (typeof characteristicGroupId !== undefined) {
			search.push(`filter[characteristic_group_id]=${characteristicGroupId}`);
		}

		const response = await agent.get(`/admin/api-shop-option-preset/list?${search.join('&')}`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		});

		const newValues = (initValues || []).map((option) => {
			return response.find((t) => +t.id === +option)
		});

		setValues(newValues);
		setOptions(response);
		onSetApiShopOptionPreset(response);
	}
	const changeValue = async (value, isConfirm) => {
		if (!isConfirm) {
			refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите изменить шаблоны? Страница товара будет перезагружена!!!`,
				acceptButtonAction: changeValue.bind(this, value, true)
			})

			return
		}

		const valueIds = (value || []).map((t) => t.id);

		onChange(valueIds);
	}

	const _getOptionLabel = (option) => {
		if (typeof option === "object") {
			return `${option.name} (№${option.id})`
		}

		return options.find((t) => t.id === Number.parseFloat(option))?.name
	}

	return (
		<>

			<Autocomplete
				value={value}
				options={options}
				size="small"
				sx={{ width: 420 }}
				className={classes.autocompleteTemplate}
				fullWidth
				multiple

				getOptionLabel={_getOptionLabel}

				renderInput={(params) => <TextField {...params} label="Шаблоны опций"/>}

				onChange={(event, value) => changeValue(value, false)}
			/>


			<DialogConfirmation
				ref={refDialogConfirmation}
			/>

		</>
	)
})

const useStyles = makeStyles(() => ({
	autocompleteTemplate: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		}
	}
}));

const validationSchema = Yup.array().of(
	Yup.object().shape({
		name: Yup.string().required('Заполните поле'),
		variants: Yup.array().min(1, "Минимуми 1 опция").of(Yup.object().shape({
			name: Yup.string().required('Заполните поле'),
			price_old_value: Yup.number().required('Заполните поле'),
			// price_value: Yup
			// 	.number()
			// 	.when(['price_old_value'], (price_old_value, schame) => {
			// 		return schame.max(Number.parseFloat(price_old_value || 0), "\"Цена со скидкой\" не может быть больше \"Базовой стоимости\"")
			// 	}),
		}))
	})
);

export default OptionsForm
