import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Checkbox,
	Collapse,
	TextField,
	Typography,
	IconButton,
	Autocomplete,
	LinearProgress,
	FormControlLabel
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";
import {
	FilterBaseCheckbox,
	FilterBaseSelectArray,
	FilterBaseString,
	FilterBaseToggleStatus,

	UploadImages,
	CKEditor
} from "../../../../../components";
import clsx from "clsx";
import agent from "../../../../../agent/agent";
import {IMaskInput} from "react-imask";
import AutocompleteCharacteristics from "../../../ProductEdit/components/Characteristics/form/Autocomplete";

let timeOutSetSortedAttributes = null;

class DialogMultiChangeProducts extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			openedCollapse: [],
			sortedAttributes: [],

			form: {},

			searchValue: "",

			isOpen: false,
			isDisabledAccept: true,
			isLoadSortedAttributes: true
		}
	}

	open = ({ onSubmit }) => {
		this.setState({
			onSubmit,
			isOpen: true
		}, () => {
			clearTimeout(timeOutSetSortedAttributes);
			timeOutSetSortedAttributes = setTimeout(async () => {
				await this.setSortedAttributes();
			}, 300);
		})
	}
	close = () => {
		this.setState({
			openedCollapse: [],
			form: {},
			isOpen: false
		})
	}

	changeFormValue = ({ target }) => {
		const { name, value } = target;
		console.log('{ name, value }: ', { name, value });

		let form = {...this.state.form};
		if (!form[name]) {
			form[name] = {
				value: null,
				isChecked: false
			}
		}
		form[name].value = value;

		this.setState({ form })
	}
	changeFormImage = ( file ) => {}
	changeFormBoolean = ({target}, value) => {
		const { name } = target;
		let form = {...this.state.form};
		if (!form[name]) {
			form[name] = {
				value: null,
				isChecked: form[name]?.isChecked || false
			}
		}
		form[name].value = Number(value);

		this.setState({
			form
		});
	}
	changeAutocompleteExtraProductSetParents = (value, name) => {
		let form = {...this.state.form};
		if (!form[name]) {
			form[name] = {
				value: [],
				isChecked: false
			}
		}
		form[name].value = value?.value || [];

		this.setState({ form })
	}
	changeFormChecked = ({ target }, value) => {
		const { name } = target;

		let form = { ...this.state.form };
		if (!form[name]) {
			form[name] = {
				isChecked: null
			}
		}
		form[name].isChecked = value;
		let isDisabledAccept = !Object.keys(form).find((t) => form?.[t]?.isChecked);

		this.setState({
			form,
			isDisabledAccept
		});
	}

	changeOpenedCollapse = (name) => {
		let openedCollapse = [...this.state.openedCollapse];

		const index = openedCollapse.findIndex((t) => t === name);
		if (index > -1) {
			openedCollapse.splice(index, 1)
		} else {
			openedCollapse.push(name)
		}

		this.setState({ openedCollapse })
	}
	changeSearchValue = async ({ target }) => {
		clearTimeout(timeOutSetSortedAttributes);

		await this.setState({
			searchValue: target.value,
			isLoadSortedAttributes: true
		})

		timeOutSetSortedAttributes = setTimeout(async () => {
			await this.setSortedAttributes();
		}, 500);
	}

	onSubmit = () => {
		const sections = this.state.sortedAttributes;
		let form = {...this.state.form};

		Object.keys(form).map((key) => {
			const section = sections.find((t) => (t.attributes || []).find((t) => String(t?.nameField || t?.id) === String(key)));
			const defaultValue = section?.defaultValue;
			const sectionKey = section?.sectionKey;

			if (!form[key]['value']) {
				form[key]['value'] = defaultValue;
			}
			if (form[key]['value'] && sectionKey === "characteristic") {
				form[key]['value'] = Array.isArray(form[key]['value']) ? form[key]['value'].map((t) => t.value || t) : form[key]['value']?.value || form[key]['value'];
			}
		});

		this.state.onSubmit(
			false,
			{
				form: form
			}
		)
	}

	setSortedAttributes = () => {
		const { adminSharacteristics: initAdminSharacteristics } = this.props;
		const search = (this.state.searchValue || "").toLocaleLowerCase();
		let attributes = (this.props.attributes || []).filter((t) => {
      const itemKey = t.key;
			const itemType = t.type;
			const itemGroup = t.group;

			return Boolean(
        itemKey === 'category_id' ||
        itemKey === 'price_new' ||
				itemType === "text" ||
				itemType === "number" ||
				itemType === "decimal" ||
				itemType === "toggleStatus" ||
				t.nameField === "brand_id" ||

				itemGroup === "characteristic"
			)
		});
		let adminSharacteristics = (initAdminSharacteristics || []).filter((t) => !t.is_readonly);

		if (!!search) {
			attributes = [...attributes].filter((t) => {
				const label = (t.label || '').toLowerCase();

				return Boolean(label.indexOf(search) > -1)
			})
			adminSharacteristics = [...adminSharacteristics].filter((t) => {
				const name = (t.name || '').toLowerCase();

				return Boolean(name.indexOf(search) > -1)
			})
		}

    const mainAttributes = ([...attributes] || [])
      .filter((t) => Boolean((
        (t.type === "toggleStatus" && t.group !== "characteristic") || t.nameField === "brand_id" || t.key === 'price_new' || t.key === 'category_id') &&
      !Boolean(t.readonly || t?.objectType?.readonly)))
      .map((t) => {
        let label = t?.label;
        if (t.key === 'price_new') {
          label = "Цена"
        }
				if (t.key === 'category_id') {
					return {
						...t,
						multiple: true,
						label: 'Удалить дополнительную категорию',
						key: 'delete_category_assignments_ids',
						nameField: 'delete_category_assignments_ids',
					}
				}

        return {
          ...t,
          label
        }
      })

		let sections = [
			{
				label: "Основные",
				sectionKey: "main",
				attributes: mainAttributes,
				defaultValue: 0
			},
			{
				label: "Характеристики",
				sectionKey: "characteristic",
				attributes: adminSharacteristics,
				defaultValue: ""
			},
		]
		sections[0].attributes.push(...[
			{
				label: "Добавление к товару-комплекту",
				name: "Добавление к товару-комплекту",
				nameField: "append_product_set_parent_ids",
				type: "autocompleteExtraProductSetParents",
			},
			{
				label: "Удаление из товара-комплекта",
				name: "Удаление из товара-комплекта",
				nameField: "delete_product_set_parent_ids",
				type: "autocompleteExtraProductSetParents",
			},
			{
				label: "Сортировка",
				name: "Сортировка",
				nameField: "sort",
				type: "number",
			},
		]);
		this.setState({
			sortedAttributes: sections,
			isLoadSortedAttributes: false
		})
	}

	render () {
		const {
			form,
			isOpen,
			searchValue,
			openedCollapse,
			isDisabledAccept,
			sortedAttributes,
			isLoadSortedAttributes
		} = this.state;
		const {
			classes
		} = this.props;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="md"
			>

				<DialogTitle>
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item>
							<Typography variant="h3">Массовое изменение атрибутов</Typography>
						</Grid>
						<Grid item>
							<TextField
								value={searchValue}
								placeholder="Поиск по атрибутам"
								fullWidth

								className={classes.searchInput}
								onChange={this.changeSearchValue}
							/>
						</Grid>
					</Grid>
				</DialogTitle>

				<DialogContent sx={{backgroundColor: "#F4F4F4"}}>
					{Boolean(isLoadSortedAttributes) && (
						<LinearProgress sx={{margin: "-20px -24px 20px -24px"}}/>
					)}

					<Grid container rowSpacing={2}>
						{sortedAttributes.map((section, indexSection) => (
							<Grid
								key={`dialog-multi-change-section-${ indexSection }`}
								item
								xs={12}
							>
								<Box className={clsx({
										[classes.section]: true,
										[classes.sectionOpen]: openedCollapse.includes(section.sectionKey),
									})}>
									<Box
										className={classes.sectionHead}
										onClick={this.changeOpenedCollapse.bind(this, section.sectionKey)}
									>
										{ section.label }

										<IconButton>
											<KeyboardArrowDownIcon
												sx={{ color: "white", fontSize: 32 }}
											/>
										</IconButton>
									</Box>
									<Collapse in={openedCollapse.includes(section.sectionKey)} timeout="auto">
										<Box className={classes.sectionBody}>
											<Grid container rowSpacing={1}>
												{(section.attributes || []).map((attribute, index) => {
													let value = form[attribute.nameField]?.value || form[attribute.id]?.value || section.defaultValue;
													if (Boolean(attribute.type === "autocompleteExtraProductSetParents" || attribute.multiple)) {
														value = form[attribute.nameField]?.value || []
													}

													const isChecked = form[attribute.nameField]?.isChecked || form[attribute.id]?.isChecked || false;
													let ComponentItem = Boolean(section.sectionKey === "characteristic") ? AttributeItemCharacteristics : AttributeItem;
													if (attribute.type === "autocompleteExtraProductSetParents") {
														ComponentItem = AutocompleteExtraProductSetParents;
													}

													return (
														<Grid key={`dialog-multi-change-attribute-${ attribute.nameField }-${ index }`} item xs={12}>
															<ComponentItem
																attribute={attribute}
																classes={classes}
																value={value}

																isChecked={isChecked}

																onChangeFormValue={this.changeFormValue}
																onChangeFormImage={this.changeFormImage}
																onChangeFormBoolean={this.changeFormBoolean}
																onChangeFormChecked={this.changeFormChecked}
																onChangeAutocompleteExtraProductSetParents={this.changeAutocompleteExtraProductSetParents}
															/>
														</Grid>
													)
												})}
											</Grid>
										</Box>
									</Collapse>
								</Box>
							</Grid>
						))}
					</Grid>
				</DialogContent>

				<DialogActions>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Button
								variant="outlined"
								color="primary"
								fullWidth
								onClick={this.close}
							>Отменить</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								disabled={isDisabledAccept}
								onClick={this.onSubmit}
							>Применить</Button>
						</Grid>
					</Grid>
				</DialogActions>

			</Dialog>
		)
	}
}

const itemsOfLine = ['checkbox', 'toggleStatus'];
const AttributeItem = React.memo((props) => {
	const {
		attribute,
		classes,
		value,

		isChecked,

		onChangeFormValue,
		onChangeFormImage,
		onChangeFormChecked,
		onChangeFormBoolean
	} = props;
	const attributeType = attribute?.type || "";
	const nameField = attribute?.nameField || "";

	const _renderFormItem = () => {
		if (attributeType === "text" || attributeType === "number") {
			return (
				<FilterBaseString
					{...attribute}

					value={value}
					name={nameField}
					isOnlyForm={true}
					onChange={onChangeFormValue}
				/>
			)
		}
		if (attributeType === "checkbox") {
			return (
				<FilterBaseCheckbox
					{...attribute}

					value={value}
					name={nameField}
					isOnlyForm={true}
					onChange={onChangeFormBoolean}
				/>
			)
		}
		if (attributeType === "toggleStatus") {
			return (
				<FilterBaseToggleStatus
					{...attribute}

					value={value}
					name={nameField}
					isOnlyForm={true}
					onChange={onChangeFormBoolean}
				/>
			)
		}
		if (attributeType === "selectArray") {
			return (
				<FilterBaseSelectArray
					{...attribute}

					label=""
					value={value}
					name={nameField}
					isOnlyForm={true}
					onChange={onChangeFormValue}
				/>
			)
		}
		if (attributeType === "image") {
			return (
				<UploadImages
					{...attribute}

					value={value}
					name={nameField}
					isOnlyForm={true}

					onChange={onChangeFormImage}
				/>
			)
		}
		if (attributeType === "textarea") {
			return (
				<FilterBaseString
					{...attribute}

					value={value}
					name={nameField}
					isOnlyForm={true}
					multiline={true}
					rows={4}
					onChange={onChangeFormValue}
				/>
			)
		}
    if (attributeType === 'sitis\\plugins\\admin\\plugins\\EditPlugin') {
      return (
        <FilterBaseString
          {...attribute}

          value={String(value || '')}
          name={nameField}
          isOnlyForm={true}
          onChange={onChangeFormValue}

          InputProps={{
            inputComponent: CustomInputAmount
          }}
        />
      )
    }
	}

	return (
		<Box className={classes.formItem}>
			<Grid container spacing={3}>
				<Grid
					item
					className={clsx({
						[classes.formItemMain]: true,
						[classes.formItemMainLine]: itemsOfLine.includes(attributeType),
					})}
				>
					<Typography variant="subtitle1" sx={{marginBottom: "4px"}}>
						{ attribute.label }
					</Typography>
					{_renderFormItem()}
				</Grid>
				<Grid item>
					<FormControlLabel
						control={(
							<Checkbox
								value={isChecked}
								checked={isChecked}
								name={attribute.nameField}

								onChange={onChangeFormChecked}
							/>
						)}
						label="Изменить"
					/>
				</Grid>
			</Grid>
		</Box>
	)
})
const AttributeItemCharacteristics = React.memo((props) => {
	const {
		attribute,
		classes,
		value,

		isChecked,

		onChangeFormValue,
		onChangeFormChecked
	} = props;
	const attributeType = attribute?.type || "";
	const nameField = attribute?.id || "";

	const handleChangeAutocomplete = ({ target }, selected) => {
		onChangeFormValue({
			target: {
				value: selected,
				name: String(nameField)
			}
		})
	}
	const handleChangeCharacteristics = ({ target }) => {
		onChangeFormValue({
			target: {
				value: target.value,
				name: String(nameField)
			}
		})
	}

	const _renderItem = () => {
		if (attribute.is_textarea && attribute?.view_template !== 'single_text') {
			return (
				<CKEditor
					value={value}
					name={nameField}
					onChange={onChangeFormValue}
				/>
			)
		}
		if (attribute?.view_template === 'single_text') {
			return (
				<TextField
					value={value}
					name={nameField}
					fullWidth
					onChange={onChangeFormValue}
				/>
			)
		}

		return (
			<AutocompleteCharacteristics
				key={`AutocompleteCharacteristics-${ String(attribute.id) }`}
				value={Boolean(attribute.is_multiple) ? (value || []) : (value || null)}
				initOptions={[]}
				name={String(attribute.id)}
				placeholder="Выберите"
				multiple={attribute.is_multiple}
				onChange={handleChangeCharacteristics}
			/>
		)
		return (
			<AutocompleteCharacteristics
				value={Boolean(attribute.is_multiple) ? (value || []) : (value || null)}
				multiple={attribute.is_multiple}
				initOptions={[]}
				name={String(nameField)}
				size="small"
				renderInput={params => (<TextField {...params}/>)}
				onChange={handleChangeAutocomplete}
				className={classes.autocomplete}
				getOptionLabel={(t) => t?.label || t?.value || "-"}
			/>
		)
	}

	return (
		<Box className={classes.formItem}>
			<Grid container spacing={3}>
				<Grid
					item
					className={clsx({
						[classes.formItemMain]: true,
						[classes.formItemMainLine]: itemsOfLine.includes(attributeType),
					})}
				>
					<Typography variant="subtitle1" sx={{marginBottom: "4px"}}>
						{ attribute.name }
					</Typography>
					{_renderItem()}
				</Grid>
				<Grid item>
					<FormControlLabel
						control={(
							<Checkbox
								value={isChecked}
								checked={isChecked}
								name={nameField}

								onChange={onChangeFormChecked}
							/>
						)}
						label="Изменить"
					/>
				</Grid>
			</Grid>
		</Box>
	)
})

const CustomInputAmount = React.forwardRef(function TextMaskCustom(props, ref) {
  const {...other} = props;

  return (
    <IMaskInput
      {...other}
      mask={Number}
      thousandsSeparator=""
      radix="."

      inputRef={ref}
      unmask={true}
    />
  );
})

let timeOutSearch = null;
const AutocompleteExtraProductSetParents = React.memo((props) => {
	const {
		value,
		classes,
		attribute,
		onChangeAutocompleteExtraProductSetParents,

		isChecked,
		onChangeFormChecked
	} = props;
	const [values, setValues] = React.useState([]);
	const [options, setOptions] = React.useState([]);
	const [search, setSearch] = React.useState("");
	const [isLoading, setLoading] = React.useState(false);

	React.useEffect(() => {
		( async () => {
			await handleUpdateListValues();
		})();
	}, [value]);

	const handleUpdateListValues = async () => {
		let newValues = [];
		await Promise.all(value.map(async (valueId) => {
			const response = await agent.get(`/admin/api-shop-product-set-parent/${ valueId }?fields=id,name`).then((res) => {
				return res.data
			}).catch(() => {
				return {}
			})
			newValues.push(response);
		}));
		setValues(newValues);
	}
	const handleChangeSearch = (event, search) => {
		clearTimeout(timeOutSearch);

		setSearch(search);
		setLoading(Boolean(!!search));

		timeOutSearch = setTimeout(async () => {
			await handleGetListOptions();
		}, 1000)
	}
	const handleGetListOptions = async () => {
		if (!search) {
			return
		}
		const response = await agent.get(`/admin/api-shop-product-set-parent/search?fields=id,name&query=${ search }`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		setOptions(response);
		setLoading(false);
	}
	const handleChange = (event, values) => {
		let valuesIds = [...values].map((t) => t.id);
		onChangeAutocompleteExtraProductSetParents({value: valuesIds}, attribute.nameField);
	}


	const _getOptionLabel = (params) => {
		return params?.name || params?.id || ""
	}

	return (
		<Box className={classes.formItem}>
			<Grid container spacing={3}>
				<Grid
					item
					className={clsx({
						[classes.formItemMain]: true
					})}
				>
					<Typography variant="subtitle1" sx={{marginBottom: "4px"}}>
						{ attribute.label }
					</Typography>
					<Autocomplete
						value={values}
						getOptionLabel={_getOptionLabel}
						options={options}
						loading={isLoading}
						onInputChange={handleChangeSearch}
						autoComplete={true}
						disableClearable={true}
						limitTags={2}
						multiple
						size="small"
						inputValue={search}
						filterSelectedOptions
						onChange={handleChange}
						renderInput={(params) => (
							<TextField
								{...params}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<FormControlLabel
						control={(
							<Checkbox
								value={isChecked}
								checked={isChecked}
								name={attribute.nameField}

								onChange={onChangeFormChecked}
							/>
						)}
						label="Изменить"
					/>
				</Grid>
			</Grid>
		</Box>
	)
});

const styles = {
	searchInput: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			color: "black!important",
			width: 240
		}
	},

	formItem: {
		backgroundColor: "#F4F4F4",
		borderRadius: 6,
		padding: "4px 12px"
	},
	formItemMain: {
		flex: 1
	},
	formItemMainLine: {
		display: "flex",
		alignItems: "center",
	},


	section: {},
	sectionOpen: {
		"& $sectionHead": {
			borderRadius: "4px 4px 0 0",
			"& .MuiButtonBase-root": {
				transform: "rotate(180deg)"
			}
		},
	},
	sectionHead: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		borderRadius: 4,
		padding: "0 12px 0 16px",
		backgroundColor: "#3855EC",
		cursor: "pointer",

		fontSize: 20,
		lineHeight: "32px",
		color: "white",
		fontWeight: "600"
	},
	sectionBody: {
		padding: "8px 12px",
		backgroundColor: "white",
	},


	autocomplete: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		}
	},
}
DialogMultiChangeProducts = withStyles(styles)(DialogMultiChangeProducts)

export default DialogMultiChangeProducts
