import React from "react";
import {
	Box,
	Grid,
	Chip,
	Checkbox,
	Typography,

	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	OpenInNewOutlined as OpenInNewOutlinedIcon
} from "@mui/icons-material";
import agent from "../../../../../agent/agent";

const AboutEntryGoods = ({ productId }) => {

	const [categories, setCategories] = React.useState([]);
	const [filterPages, setFilterPages] = React.useState([]);
	const [compilations, setCompilations] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(true);
	const getItems = async () => {
		const res = await agent.get(`/api/product-info/get/${ productId }`)
			.then((res) => {
				return res?.data || {}
			})
			.catch(() => {
				return {}
			});

		let _categories = [...(res?.categories || [])].map((t) => {
			return {
				...t,
				url: t?.website_url,
				sort: t?.sort,
				title: t?.category?.full_name,
				status: Boolean(t?.category?.status),
			}
		});
		let _filterPages = [...(res?.filter_pages || [])].map((t) => {
			return {
				...t,
				sort: t?.sort,
				title: t?.name,
				status: Boolean(t?.filter_page_status),
			}
		});
		let _compilations = [...(res?.compilations || [])].map((t) => {
			return {
				...t,
				sort: t?.sort,
				title: t?.name
			}
		});

		setCategories(_categories);
		setFilterPages(_filterPages);
		setCompilations(_compilations);

		setIsLoading(false);
	}
	React.useEffect(() => {
		(async () => {
			await getItems();
		})();
	}, []);


	return (
		<Box px={2} py={2} bgcolor="white" borderRadius={2}>
			<Typography variant="h3">Категории и подборки (справочно)</Typography>
			<Box mb={2}/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<SectionCompilations items={compilations} empty={Boolean(!isLoading && compilations.length <= 0)}/>
				</Grid>
				<Grid item xs={12}>
					<SectionCategories items={categories} empty={Boolean(!isLoading && categories.length <= 0)}/>
				</Grid>
				<Grid item xs={12}>
					<SectionFilterPages items={filterPages} empty={Boolean(!isLoading && filterPages.length <= 0)}/>
				</Grid>
			</Grid>
		</Box>
	)
};

const SectionCategories = ({ items, empty }) => {
	const classes = useStyles();
	return (
		<Box className={classes.section}>
			<Typography variant="h4" className={classes.sectionTitle}>Список категорий</Typography>
			<Table className={classes.sectionTable}>
				<TableHead>
					<TableRow>
						<TableCell width="40%">Наименование</TableCell>
						<TableCell width="20%">Сортировка товара</TableCell>
						<TableCell align="center" width="64px">Статус</TableCell>
						<TableCell>Ссылка</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item) => (
						<TableRow>
							<TableCell>{ item.title }</TableCell>
							<TableCell>{ item.sort }</TableCell>
							<TableCell align="center">
								<Checkbox checked={Boolean(item.status)} disabled/>
							</TableCell>
							<TableCell>
								<a href={item.url} target="_blank">
									<OpenInNewOutlinedIcon color="primary"/>
								</a>
							</TableCell>
						</TableRow>
					))}
					{Boolean(empty) && (
						<TableRow>
							<TableCell colSpan={4}>Ничего не найдено</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Box>
	)
};
const SectionFilterPages = ({ items, empty }) => {
	const classes = useStyles();
	return (
		<Box className={classes.section}>
			<Typography variant="h4" className={classes.sectionTitle}>Список посадочных</Typography>
			<Table className={classes.sectionTable}>
				<TableHead>
					<TableRow>
						<TableCell width="40%">Наименование</TableCell>
						<TableCell width="20%">Сортировка товара</TableCell>
						<TableCell align="center" width="64px">Статус</TableCell>
						<TableCell/>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item) => (
						<TableRow>
							<TableCell>{ item.title }</TableCell>
							<TableCell>
								{ item.sort }
							</TableCell>
							<TableCell align="center" width="64px">
								<Checkbox checked={Boolean(item.status)} disabled/>
							</TableCell>
							<TableCell/>
						</TableRow>
					))}
					{Boolean(empty) && (
						<TableRow>
							<TableCell colSpan={4} align="center">Ничего не найдено</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Box>
	)
};
const SectionCompilations = ({ items, empty }) => {
	const classes = useStyles();
	return (
		<Box className={classes.section}>
			<Typography variant="h4" className={classes.sectionTitle}>Список подборок</Typography>
			<Table className={classes.sectionTable}>
				<TableHead>
					<TableRow>
						<TableCell width="40%">Наименование</TableCell>
						<TableCell width="20%">Сортировка товара</TableCell>
						<TableCell/>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item) => (
						<TableRow>
							<TableCell width="40%">
								<Grid container wrap="nowrap" spacing={1}>
									<Grid item>
										{ item.title }
									</Grid>
									<Grid item>
										<Grid container spacing={0.5}>
											{(item?.types || []).map((t) => t?.title).filter((t) => !!t).map((t) => (
												<Grid item><Chip label={t}/></Grid>
											))}
										</Grid>
									</Grid>
								</Grid>
							</TableCell>
							<TableCell>{ item.sort }</TableCell>
							<TableCell/>
						</TableRow>
					))}
					{Boolean(empty) && (
						<TableRow>
							<TableCell colSpan={3} align="center">Ничего не найдено</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Box>
	)
};

const useStyles = makeStyles(() => ({
	section: {
		borderRadius: "10px",
		padding: "12px 14px",
		boxSizing: "border-box",
		border: "1px solid #E6E6E6",
		boxShadow: "0px 4px 8px 0px #0000000D",
	},
	sectionTitle: {
		marginBottom: "6px",
		fontSize: "20px",
		lineHeight: 1.2,
	},
	sectionTable: {
		"& a": {
			textDecoration: 'none',
			"&:hover": {
				textDecoration: 'underline',
			}
		},
		"& .MuiChip-root": {
			padding: "1px 4px",
			height: "auto",
			minHeight: "initial",
			backgroundColor: "transparent",
			border: "1px solid #3855EC",

			"& span": {
				padding: 0,
				fontSize: 13,
				lineHeight: 1,
				color: "#3855EC",
			}
		},
		"& .MuiTableCell-head": {
			padding: "2px 6px",
			fontSize: "14px!important",
			fontWeight: "500!important",
		},
		"& .MuiTableCell-body": {
			padding: "2px 6px!important",
			fontSize: "14px!important",
			fontWeight: "500!important",
		},
		"& .MuiCheckbox-root": {
			padding: 0,
			"&.Mui-checked": {
				color: "#838383!important"
			}
		},
		"& .MuiTableRow-root:hover .MuiTableCell-body": {
			backgroundColor: "#b4ceff"
		}
	}
}));

export default AboutEntryGoods
