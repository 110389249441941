import React, {Component} from "react";
import {ThemeProvider} from "@mui/material/styles";
import {StylesProvider, jssPreset} from "@mui/styles";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {create} from 'jss';
import {LocalizationProvider} from "@mui/lab";
import {ReactNotifications} from 'react-notifications-component'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import theme from "./theme";
import storeRedux from "./redux/store";
import Router from "./router";
import ruLocale from 'date-fns/locale/ru';
import agent from "./agent/agent";

const localeMap = {
	ru: ruLocale
};


const styleNode = document.createComment('jss-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);
const jss = create({
	...jssPreset(),
	insertionPoint: document.getElementById('jss-insertion-point'),
});

Array.prototype.contains = (where, what) => {
	if (!what) {
		return false;
	}

	return Boolean(where.find((a) => what.find((b) => b === a)))

	for (var i = 0; i < what.length; i++) {
		for (var j = 0; j < where.length; j++) {
			if (what[i] == where[j]) {
				break;
			}
			if (j === where.length - 1) {
				return false;
			}
		}
	}
	return true;
}

class App extends Component {
	constructor(props) {
		super(props);

		this.timeOutCheckVersion = null;
	}
	render() {
		return (
			<LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap.ru}>
				<ThemeProvider theme={theme}>
					<StylesProvider jss={jss}>
						<Provider store={storeRedux}>
							<ReactNotifications/>
							<BrowserRouter>
								<Router/>
							</BrowserRouter>
						</Provider>
					</StylesProvider>
				</ThemeProvider>
			</LocalizationProvider>
		)
	}
}

export default App
