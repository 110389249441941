import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography,

	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	MenuBook as MenuBookIcon,
	AddBoxOutlined as AddBoxOutlinedIcon
} from "@mui/icons-material";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";
import {setVisibleColumns} from "../../../helper/visible-columns";
import {filterParse, filterStringify, filterToStringPreset, objectsMerge} from "../../../helper/filter";
import {getPopularFilter, setPopularFilter} from "../../../helper/popular-filter";
import {
	Table as TableComponent,
	Controls as ControlsComponent,
	ProductsPresets as ProductsPresetsComponent,
	TablePagination as TablePaginationComponent,
	ProductsPopularFilters as ProductsPopularFiltersComponent,
	DialogCreateProduct as DialogCreateProductComponent,
	DialogMultiChangeProducts as DialogMultiChangeProductsComponent,
	DialogDiscountSetting as DialogDiscountSettingComponent,
	DialogMultiChangePrice as DialogMultiChangePriceComponent,
} from "./components";
import {
	DialogConfirmation
} from "../../../components";
import {convertorCaseWords} from "../../../helper/convertor";
import {Notification, NotificationTypes} from "../../../common/Notification";
import {getIdCharacteristicTextarea} from "../../../common/Characteristic";
import {checkInitialOverlap} from "react-range/lib/utils";

const lodash = require("lodash")

const initFilter = {
	page: 1,
	'per-page': 20,
	"sort": "-id"
};
const pageName = "products";

class Products extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			products: [],
			popularFilter: [],
			visibleColumns: [],
			adminSharacteristics: [],

			filter: {...initFilter},
			pagination: {},
			selected: {
				ids: [],
				isAll: false
			},

			isOpenFilter: false,
			isLoadProducts: true,
			isOpenBackdrop: false,
		};

		this.refFilterComponent = React.createRef();
		this.refDialogConfirmation = React.createRef();
		this.refDialogCreateProduct = React.createRef();
		this.refDialogDiscountSetting = React.createRef();
		this.refDialogMultiChangePrice = React.createRef();
		this.refDialogMultiChangeProducts = React.createRef();
		this.timeOutVisibleColumn = null;
	}

	componentDidMount = async () => {
		await this.props.getPageProductsSettings();

		await this.setFilterPage();
		await this.setColumnsPage();
		await this.setPopularFilter();
		await this.setAdminSharacteristics();
	}
	componentDidUpdate = async (prevProps) => {
		if (JSON.stringify(prevProps.activePreset) !== JSON.stringify(this.props.activePreset)) {
			if (this.state.isLoadProducts) {
				return
			}
			const filter = this._getFilterPage();
			await this._replaceFilterUrl({
				...filter,
				preset: this.props.activePreset?.slug,
			}, true);
			await this.setColumnsPage();
		}
		if (prevProps.location.search !== this.props.location.search && this.props.location.search === "") {
			await this.props.setActivePreset({});
			await this.setColumnsPage();
			await this.setFilterPage();
			await this.getProducts();
		}
	}

	// Инициализацяи страниц
	setColumnsPage = async () => {
		let initPageColumns = await agent.get(urls.getProductsColumns).then((res) => {
			let data = res.data;

			if (!data.find((t) => t.name === 'id')) {
				data.unshift({
					label: "ID",
					name: "id"
				})
			}

			return data
		}).catch(() => {
			return []
		});
		initPageColumns = initPageColumns.map((column, idx) => {
			const isTypeObject = Boolean(typeof column.type !== "string");

			return {
				...column,
				type: isTypeObject ? (column?.type?.["0"] || column?.type?.['class']) : column?.type,
				name: column.label,
				nameField: column.name,
				key: column.name,
				options: column?.type?.data || column?.options || column?.data || [],
				omit: true,

				objectType: Boolean(isTypeObject) ? column.type : {}
			}
		});

		// const visibleColumns = getVisibleColumns(pageName, initPageColumns, visibleColumnsProducts);
		const visibleColumns = await this._setVisibleColumns(initPageColumns);

		await this.setState({visibleColumns})

		await this.getProducts();
	}
	setFilterPage = async () => {
		let filter = this._getFilterPage();
		await this.setState({filter})
	}
	setPopularFilter = async () => {
		const popularFilter = getPopularFilter(pageName);

		await this.setState({popularFilter});
	}
	setAdminSharacteristics = async () => {
		const fields = [
			'id',
			'name',
			'type',
			'is_multiple',
			'is_textarea',
			'is_readonly',
			'view_template'
		].join(",");
		const expand = [
			'is_multiple',
			'is_textarea',
			'is_readonly',
			'view_template'
		].join(",");

		const adminSharacteristics = await agent.get(`/admin/api-shop-characteristics/list?fields=${fields}&expand=${expand}&per-page=200`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})
		this.setState({
			adminSharacteristics
		});
	}

	resetDefaultSettings = async (visibleColumns) => {

		setVisibleColumns(pageName, visibleColumns);
		await this.setState({visibleColumns});

		await this.setColumnsPage();
		await this.setFilterPage();
		await this.setPopularFilter();
		await this.setAdminSharacteristics();
	}

	// Управление товарами
	getProducts = async () => {
		this.setState({isLoadProducts: true})

		const formData = this._getFilterBody();
		const {data, headers} = await agent.post(`${urls.getProducts}`, formData).catch((err) => {
			Notification({
				message: err.response?.data[0]?.message || "Ошибка сервера",
				type: NotificationTypes.error
			});

			return {data: [], headers: {}}
		});

		this.setState({
			products: data,
			isLoadProducts: false
		});

		await this._changePagination(headers);
	}

	// Управление страницей
	_changePagination = async (headers) => {
		let pagination = {...this.state.pagination};
		pagination.count = headers?.['x-pagination-page-count'] || 1;
		pagination.perPage = headers?.['x-pagination-per-page'] || 20;
		pagination.totalCount = headers?.['x-pagination-total-count'] || 0;

		this.setState({pagination});
	}
	_changePopularFilter = async (popularFilter) => {
		this.setState({popularFilter});
		setPopularFilter(pageName, popularFilter);
	}
	_changeVisibleColumns = async (visibleColumns) => {
		clearTimeout(this.timeOutVisibleColumn);

		await this.setState({visibleColumns});

		setVisibleColumns(pageName, visibleColumns);

		this.timeOutVisibleColumn = setTimeout(async () => {
			await this.getProducts();
		}, 1000);
	}
	_getVisibleColumns = () => {
		const visibleColumns = [...this.state.visibleColumns];

		return Object.keys(visibleColumns)
			.map((key) => {
				if (!visibleColumns[key].omit) {
					return visibleColumns[key]
				}
			})
			.filter(t => !!t)
	}

	// Логика работы с выбранными строками
	changeSelectedRows = async (selected) => {
		await this.setState({selected});

		const selectedCount = this._selectedRows();
		const maxCount = process.env.REACT_APP_MAX_COUNT_PRODUCTS_FOR_HOT_UPDATE;
		if (!!maxCount && selectedCount > Number.parseFloat(maxCount)) {
			Notification({
				type: NotificationTypes.warning,
				message: `Массовое изменение товаров затронет более ${maxCount} шт. товаров. Задача будет поставлена в очередь и запущена на исполнение. Просмотр статуса выполнения задачи будет доступен по кнопке "колокольчик" сверху справа.`,
				duration: 10000
			})
		}
	}
	clearAllSelectedRows = async () => {
		this.setState({
			selected: {
				ids: [],
				isAll: false
			}
		})
	}

	// Создание товара
	createProduct = async (product) => {
		if (Object.keys(product || {}).length <= 0) {
			this.refDialogCreateProduct.current.open({
				onSubmit: this.createProduct.bind(this)
			})

			return
		}

		this.setState({isOpenBackdrop: true})

		const _code = product.code;
		const bodyFilter = {
			fields: "id,code",
			page: 1,
			'per-page': 2,
			'filter': {code: _code}
		};
		const isSuccessSkuProduct = await agent.post('/api/admin/products/index', bodyFilter).then((res) => {
			return Boolean((res?.data || []).length <= 0)
		}).catch(() => {
			return true
		});
		if (!isSuccessSkuProduct) {
			this.setState({isOpenBackdrop: false})
			Notification({
				type: NotificationTypes.error,
				message: "Товар с таким артикулом уже существует"
			})
			return
		}

		const response = await agent.post(urls.editProduct, product).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response || true}
		})
		if (response.error) {
			this.setState({isOpenBackdrop: false})

			const errorsArray = response.error?.data || [];
			const errorMessage = errorsArray
				.map(t => t.message)
				.join("<br/><br/>")
			Notification({
				message: errorMessage || "Возникла ошибка создания товара",
				type: NotificationTypes.error
			})

			return
		}

		this.refDialogCreateProduct.current.close();

		this.setState({isOpenBackdrop: false})

		this._routePageEdit(response.id);
	}
	duplicateProduct = async (column, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите дублировать товар "${column.name}"?`,
				acceptButtonTitle: "Да, дублировать",
				cancelButtonTitle: "Отменить",
				acceptButtonAction: this.duplicateProduct.bind(this, column, true),
			})

			return
		}

		this.setState({isOpenBackdrop: true})

		const response = await agent.post(`api/admin/products/duplicate/${column.id}`)
			.then(res => res.data)
			.catch(err => {
				return {error: err}
			})

		if (response.error) {
			this.setState({isOpenBackdrop: false})

			Notification({
				message: "Возникла ошибка дублирования товара",
				type: NotificationTypes.error
			})

			return
		}

		Notification({
			message: "Товар успешно дублирован",
			type: NotificationTypes.success
		});

		await this.getProducts();

		this.setState({isOpenBackdrop: false})
	}

	// Массовое изменение атрибутов товаров
	changeMultiAttributesProducts = async (isConfirm, form) => {

		// Вызов формы
		if (Object.keys(form || {}).length <= 0) {
			this.refDialogMultiChangeProducts.current.open({
				onSubmit: this.changeMultiAttributesProducts.bind(this)
			})

			return
		}

		const totalSelectedRows = this._selectedRows();

		// Запрос на подтвреждение
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите изменить ${totalSelectedRows} товаров`,
				acceptButtonAction: this.changeMultiAttributesProducts.bind(this, true, form)
			})

			return
		}

		this.setState({isOpenBackdrop: true});
		const { adminSharacteristics } = this.state;

		// Анализ и отправка изменение
		const formFilter = Object.keys((form?.form || {})).filter((key) => Boolean(form?.form[key]?.isChecked));
		let body = this._getInitBodyForMultiChange();
		body.data = {};

		for (const key of formFilter) {
			const objectItem = form?.form[key];
			const characteristic = ( adminSharacteristics || [] ).find((t) => +t.id === +key) || null;
			const isSingleText = Boolean(characteristic?.view_template === 'single_text');

			if (!characteristic) {
				body['data'][key] = objectItem?.value;
			}
			if (!!characteristic && !characteristic?.is_textarea) {
				body['data']['values'] = {
					...body['data']['values'],
					[key]: objectItem?.value
				};
			}
			if (!!characteristic && !!characteristic?.is_textarea && isSingleText) {
				body['data']['values'] = {
					...body['data']['values'],
					[key]: objectItem?.value
				};
			}
			if (!!characteristic && !!characteristic?.is_textarea && !isSingleText) {
				body['data']['values'] = {
					...body['data']['values'],
					[key]: await getIdCharacteristicTextarea({ characteristic, text: objectItem?.value })
				};
			}
		}

		const totalSelected = this._selectedRows();

		// Получение кол-во товаров для которых отмениться скидка
		const apiChangedCount = await agent.post(`/api/admin/products/multiple-update-info`, body).then((res) => {
			return res.data?.count || 0
		}).catch(() => {
			return {error: true}
		})
		if (+totalSelected !== +apiChangedCount || apiChangedCount.error) {
			this.setState({isOpenBackdrop: false});

			Notification({
				type: NotificationTypes.error,
				message: `Запрашиваемое количество товаров на обновление (${totalSelected}шт) не совпадает с количеством товаров, для которых будет применено это действие (${apiChangedCount}шт)`
			})

			return
		}

		// Проверка что бы товары изменились
		let apiUrl = this._apiUrlUpdateMultiProducts();
		const responseCheckCount = await agent.put(apiUrl, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (!responseCheckCount.success && (responseCheckCount.error || +responseCheckCount.count !== +totalSelectedRows)) {
			this.setState({isOpenBackdrop: false});

			Notification({
				type: NotificationTypes.error,
				message: responseCheckCount.error || "Произошла ошибка при изменении, повторите попытку позднее"
			})

			return
		}

		await this.getProducts();
		await this.clearAllSelectedRows();

		this.setState({isOpenBackdrop: false});

		this.refDialogMultiChangeProducts.current.close();

		Notification({
			message: responseCheckCount?.success || "Товары успешно обновлены",
			type: NotificationTypes.success,
		})
	}

	// Установка множественной скидки для товаров
	setMultiDiscountProducts = async (isConfirm, stage, data, type) => {
		const {selected} = this.state;

		let filterBody = {}
		if (!selected.isAll) {
			filterBody = {
				filter: {
					id: selected.ids,
					is_product_set: 0
				}
			}
		}
		if (!!selected.isAll) {
			filterBody.filter = this._getMergeFilterMultiChange({...(filterBody.filter || {})});
		}

		// Запуск модального окна
		if (stage === 1 && !isConfirm) {
			this.refDialogDiscountSetting.current.open({
				type: type,
				onSubmit: this.setMultiDiscountProducts.bind(this)
			})

			return
		}

		// Проверка на бекенде выбранных данных и параметров
		if (stage === 1 && !!isConfirm) {
			this.setState({isOpenBackdrop: true});

			const totalSelected = this._selectedRows()
			const apiChangedCount = await agent.post(`/api/admin/products/multiple-update-info`, filterBody).then((res) => {
				return res.data?.count || 0
			}).catch(() => {
				return {error: true}
			})
			if (+totalSelected !== +apiChangedCount || apiChangedCount.error) {
				this.setState({isOpenBackdrop: false});

				Notification({
					type: NotificationTypes.error,
					message: "Произошла ошибка при изменении, повторите попытку позднее"
				})

				return
			}

			this.setState({isOpenBackdrop: false});

			this.refDialogDiscountSetting.current.close();
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы точно хотите установить скидку для ${apiChangedCount} товаров`,
				acceptButtonTitle: "Да, установить",
				acceptButtonAction: this.setMultiDiscountProducts.bind(this, true, 2, data, type)
			})

			return
		}

		// Отправка данных для редактирования
		if (stage === 2) {
			this.setState({isOpenBackdrop: true});

			await this.cancelMultiDiscountProducts(true, true)

			const apiUrl = this._apiUrlUpdateMultiProducts();

			// Обновление цена
			filterBody.data = {
				change_price: {
					target: "product",
					column: "price_new",
					operation: "decrease",
					type: type,
					price_rounding_method: data.priceRoundingMethod,
					value: data.value
				}
			}
			const responseUpdateDataPrice = await agent.put(apiUrl, filterBody).then((res) => {
				return res.data
			}).catch((err) => {
				return {error: err.response}
			})
			if (responseUpdateDataPrice.error) {
				this.setState({isOpenBackdrop: false});

				Notification({
					type: NotificationTypes.error,
					message: "Произошла ошибка при изменении, повторите попытку позднее"
				})

				return
			}

			// Обноваление параметров
			if (data.applyParameters) {
				filterBody.data = {
					change_price: {
						target: "option",
						column: "price_new",
						operation: "decrease",
						type: type,
						price_rounding_method: data.priceRoundingMethod,
						value: data.value
					}
				}
				const responseUpdateDataParams = await agent.put(apiUrl, filterBody).then((res) => {
					return res.data
				}).catch((err) => {
					return {error: err.response}
				})
				if (responseUpdateDataParams.error) {
					this.setState({isOpenBackdrop: false});

					Notification({
						type: NotificationTypes.error,
						message: "Произошла ошибка при изменении, повторите попытку позднее"
					})

					return
				}
			}

			await this.getProducts();
			await this.clearAllSelectedRows();

			this.setState({isOpenBackdrop: false});

			Notification({
				type: NotificationTypes.success,
				message: "Скидка успешно применена"
			})
		}
	}

	// Массовая отмена скидки
	cancelMultiDiscountProducts = async (isConfirm, isOnliMethod = false) => {
		const totalSelected = this._selectedRows();

		// Запрос подтверждения на отмену скидок
		if (!isConfirm && !isOnliMethod) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждения",
				message: `Вы действительно хотите отменить скидку у ${totalSelected} товар.`,
				acceptButtonTitle: "Да, отменить",
				acceptButtonAction: this.cancelMultiDiscountProducts.bind(this, true, isOnliMethod)
			})

			return
		}

		if (!isOnliMethod) {
			this.setState({isOpenBackdrop: true});
		}

		const {
			selected
		} = this.state;

		let filterBody = {}
		if (!selected.isAll) {
			filterBody = {
				filter: {
					id: selected.ids,
					is_product_set: 0
				}
			}
		}
		if (!!selected.isAll) {
			filterBody.filter = this._getMergeFilterMultiChange({...(filterBody.filter || {})});
		}

		const apiUrl = this._apiUrlUpdateMultiProducts();

		// Получение кол-во товаров для которых отмениться скидка
		const apiChangedCount = await agent.post(`/api/admin/products/multiple-update-info`, filterBody).then((res) => {
			return res.data?.count || 0
		}).catch(() => {
			return {error: true}
		})
		if (+totalSelected !== +apiChangedCount || apiChangedCount.error) {
			this.setState({isOpenBackdrop: false});

			Notification({
				type: NotificationTypes.error,
				message: `Запрашиваемое количество товаров на обновление (${totalSelected}шт) не совпадает с количеством товаров, для которых будет применено это действие (${apiChangedCount}шт)`
			})

			return
		}

		// Отмена скидки для выбранных товаров
		filterBody.data = {
			reset_price_new_from_price_old: true
		}
		const responseUpdateDataParams = await agent.put(apiUrl, filterBody).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (responseUpdateDataParams.error) {
			this.setState({isOpenBackdrop: false});

			Notification({
				type: NotificationTypes.error,
				message: responseUpdateDataParams.error?.data?.errorMessage || "Произошла ошибка при изменении, повторите попытку позднее"
			})

			return
		}

		await this.clearAllSelectedRows();

		// Обновление списка товаров
		if (!isOnliMethod) {
			await this.getProducts();
			this.setState({isOpenBackdrop: false});
			Notification({
				type: NotificationTypes.success,
				message: "Скидки успешно отменены",
			})
		}
	}

	// Массовое изменение стоимости товара
	changeMultiPriceProducts = async (isConfirm, form, type) => {

		// Заполнение формы
		if (Object.keys(form || {}).length <= 0) {
			this.refDialogMultiChangePrice.current.open({
				type: type,
				onSubmit: this.changeMultiPriceProducts.bind(this)
			})

			return
		}

		// Подтверждение пользователя
		if (!isConfirm) {
			const totalSelected = this._selectedRows();

			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите изменить стоимость ${totalSelected} товаров?`,
				acceptButtonAction: this.changeMultiPriceProducts.bind(this, true, form, type)
			})

			return
		}

		this.refDialogConfirmation.current.onClose();

		this.setState({isOpenBackdrop: true});

		const apiUrl = this._apiUrlUpdateMultiProducts();

		// Сбор параметров
		const {selected} = this.state;
		let filterBody = {
			data: {
				change_price: {
					target: "product",
					column: "price_old",
					operation: form.operation,
					type: type,
					price_rounding_method: form.price_rounding_method,
					value: form.value
				}
			}
		}
		if (!selected.isAll) {
			filterBody.filter = {
				id: selected.ids,
				is_product_set: 0
			}
		}
		if (!!selected.isAll) {
			filterBody.filter = this._getMergeFilterMultiChange({...(filterBody.filter || {})});
		}

		// Получение кол-во товаров для которых
		const totalSelected = this._selectedRows();
		const apiChangedCount = await agent.post(`/api/admin/products/multiple-update-info`, filterBody).then((res) => {
			return res.data?.count || 0
		}).catch(() => {
			return {error: true}
		})
		if (+totalSelected !== +apiChangedCount) {
			this.setState({isOpenBackdrop: false});
			Notification({
				type: NotificationTypes.error,
				message: `Отправленно на изменение ${totalSelected}, а найдено для изменения ${apiChangedCount}`
			})

			return
		}
		if (apiChangedCount.error) {
			this.setState({isOpenBackdrop: false});
			Notification({
				type: NotificationTypes.error,
				message: "Произошла ошибка при изменении, повторите попытку позднее"
			})

			return
		}

		// Изменение текущей цены
		const responseUpdateDataPrice = await agent.put(apiUrl, filterBody).then((res) => {
			const data = res.data;
			if (data.error) {
				return {
					error: {
						errorMessage: data.error
					}
				}
			}

			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (responseUpdateDataPrice.error) {
			this.setState({isOpenBackdrop: false});

			Notification({
				type: NotificationTypes.error,
				message: responseUpdateDataPrice?.error?.errorMessage || "Произошла ошибка при изменении, повторите попытку позднее"
			})

			return
		}

		// Изменение новой цены
		filterBody.data.change_price.column = "price_new";
		const responseUpdateDataPriceNew = await agent.put(apiUrl, filterBody).then((res) => {
			const data = res.data;
			if (data.error) {
				return {
					error: {
						errorMessage: data.error
					}
				}
			}

			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (responseUpdateDataPriceNew.error) {
			this.setState({isOpenBackdrop: false});

			Notification({
				type: NotificationTypes.error,
				message: responseUpdateDataPrice?.error?.errorMessage || "Произошла ошибка при изменении, повторите попытку позднее"
			})

			return
		}

		// Изменение параметров
		if (form.applyParameters) {
			filterBody.data.change_price.target = "option";
			const responseUpdateDataPriceNew = await agent.put(apiUrl, filterBody).then((res) => {
				const data = res.data;
				if (data.error) {
					return {
						error: {
							errorMessage: data.error
						}
					}
				}

				return res.data
			}).catch((err) => {
				return {error: err.response}
			})
			if (responseUpdateDataPriceNew.error) {
				this.setState({isOpenBackdrop: false});

				Notification({
					type: NotificationTypes.error,
					message: responseUpdateDataPrice?.error?.errorMessage || "Произошла ошибка при изменении, повторите попытку позднее"
				})

				return
			}

			filterBody.data.change_price.column = "price_old";
			const responseUpdateDataPriceOld = await agent.put(apiUrl, filterBody).then((res) => {
				const data = res.data;
				if (data.error) {
					return {
						error: {
							errorMessage: data.error
						}
					}
				}

				return res.data
			}).catch((err) => {
				return {error: err.response}
			})
			if (responseUpdateDataPriceOld.error) {
				this.setState({isOpenBackdrop: false});

				Notification({
					type: NotificationTypes.error,
					message: responseUpdateDataPrice?.error?.errorMessage || "Произошла ошибка при изменении, повторите попытку позднее"
				})

				return
			}
		}

		await this.getProducts();
		await this.clearAllSelectedRows();

		this.refDialogMultiChangePrice.current.close();

		this.setState({isOpenBackdrop: false})

		Notification({
			type: NotificationTypes.success,
			message: "Скидки успешно установлены"
		})
	}

	// Массовое удаление товаров
	deleteMultiProducts = async (isConfirm) => {
		const totalSelected = this._selectedRows();
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: "Вы действительно хотите удалить выбранные товары?",
				acceptButtonAction: this.deleteMultiProducts.bind(this, true)
			})

			return
		}

		await this.setState({
			isStartDeleteMultiProducts: true,
			isOpenBackdrop: true,
		})
		const {
			selected,
			isStartDeleteMultiProducts
		} = this.state;
		for await (const product of selected.ids) {
			if (isStartDeleteMultiProducts) {
				const response = await agent.delete(`api/admin/products/${product}`)
					.then(res => res.data)
					.catch(err => {
						return {error: "Ошибка"}
					});
				if (response.error) {
					return null;
				}
			} else {
				return null;
			}
		}

		this.setState({
			isStartDeleteMultiProducts: false,
			isOpenBackdrop: false
		});
		Notification({
			type: NotificationTypes.success,
			message: "Товары успешно удалены"
		})
		await this.getProducts();
		await this.clearAllSelectedRows();
	}

	// Работы с фильтром страницы
	_changeFilter = (filter, isFastStart) => {
		this.setState({
			filter
		}, async () => {
			if (!isFastStart) {
				return null
			}

			filter.page = 1;
			await this.setState({ filter });

			const urlFilter = filterStringify(this.state.filter, this.state.visibleColumns);
			window.history.replaceState(null, null, `/products?${urlFilter}`);

			await this.clearAllSelectedRows();
			await this.getProducts();
		});
	}
	_changeFilterPagination = (filter, isFastStart) => {
		this.setState({
			filter,
			selected: {
				ids: [],
				isAll: false
			}
		}, async () => {
			if (!isFastStart) {
				return null
			}

			const urlFilter = filterStringify(this.state.filter, this.state.visibleColumns);
			window.history.replaceState(null, null, `/products?${urlFilter}`);

			await this.getProducts();
		});
	}
	_replaceFilterUrl = async (filter) => {
		await this.setState({filter});
		const urlFilter = filterStringify(this.state.filter, this.state.visibleColumns);
		window.history.replaceState(null, null, `/products?${urlFilter}`);
	}
	_requestSearch = async () => {
		await this.clearAllSelectedRows();
		let filter = {...this.state.filter}
		filter.page = 1;
		await this.setState({ filter });
		await this.getProducts();
	}
	_requestSearchSetFilter = async (filter) => {
		await this.clearAllSelectedRows();
		filter.page = 1;
		await this.setState({ filter });
		await this.getProducts();
	}
	_clearFilter = async () => {
		const urlFilter = filterStringify(initFilter);
		await window.history.replaceState(null, null, `/products?${urlFilter}`);

		const filter = this._getFilterPage();
		await this.clearAllSelectedRows();
		await this.setState({filter});

		await this.getProducts();
	}
	_getFilterPage = () => {
		const search = window.location?.search || '';
		const visibleColumns = {...this.state.visibleColumns};
		const productsFilterObject = localStorage.getItem('products-filter-object');

		let filter = {};
		Object.keys(visibleColumns).map((key) => {
			filter[visibleColumns[key]?.key || ''] = "";
		})

		filter = {
			...filter,
			...filterParse(search, {}, visibleColumns),
		}

		if (Object.keys(filter).length <= 0) {
			filter = {
				...filter,
				...initFilter
			}

			if (!!productsFilterObject) {
				filter = {
					...filter,
					...filterParse(productsFilterObject, {}, visibleColumns)
				}
				localStorage.removeItem('products-filter-object');
			}
		} else {
			localStorage.removeItem('products-filter-object');
		}
    if (typeof filter.status !== "undefined" && filter.status !== "") {
      filter.status = Number(filter.status);
    }

		return filter
	}
	_getFilterBody = ({isObject} = {}) => {
		const {activePreset} = this.props;
		const {filter, visibleColumns} = this.state;
		const activeFilterKeys = Object.keys({...filter}).filter((t) => {
      return Boolean(
        typeof filter[t] !== "undefined" && filter[t] !== ""
      )
    });

		// Инициализация тела запроса
		const filterFormData = new FormData();
		const filterFormObject = {};
		let filterObject = [];

		// Инициализация и добавление полей для таблицы
		const fields = Object.keys(visibleColumns)
			.map((key) => Boolean(!visibleColumns[key].omit) ? visibleColumns[key]?.nameField || "" : "")
			.filter((t) => !!t);
		filterFormData.append("fields", [...fields, 'absoluteUrl', 'is_product_set', 'extraWebsites'].join(','));
		if ((filter?.['filter[extraWebsites]'] || []).length > 0) {
			filter?.['filter[extraWebsites]'].map((item) => {
				filterFormData.append('filter[extraWebsites][in][]', item);
			})
		}

		activeFilterKeys.map((key) => {
			const columnKey = Object.keys(visibleColumns).find((t) => visibleColumns[t]?.nameField === key);
			const column = visibleColumns?.[columnKey] || {};
			const columnType = column?.type || "string";

			if (key === "page" || key === "sort" || key === "per-page") {
				filterFormData.append(key, filter[key]);
				filterFormObject[key] = filter[key];
				filterObject.push(`${key}=${filter[key]}`);
			}
			else if (key === "filter[extraWebsites]") {
				if ((filter[key] || []).length > 0) {
					filterObject.push(`${key}=${(filter[key] || []).join(',')}`);
				}
			}
			else if (column.group === "characteristic") {
				let filterItem = filter[key];
				if (typeof filterItem === "string") {
					filterItem = filterItem.split("[]")
				}

				filterItem.map((t) => {
					filterFormData.append(`filter[${key}]`, t.value || t);
					filterFormObject[`filter[${key}]`] = t.value || t;
				})

				filterObject.push(`${key}=${filterItem.map((t) => t.value || t).join('[]')}`);
			}
			else if (key === "preset") {
				filterObject.push(`${key}=${filter[key]}`);
			}
			else if (columnType === "text" || columnType === "number") {
				filterFormData.append(`filter[${key}][like]`, filter[key]);
				filterFormObject[`filter[${key}][like]`] = filter[key];
				filterObject.push(`${key}=${filter[key]}`);
			}
			else if (columnType === "checkbox" || columnType === "toggleStatus") {
				filterFormData.append(`filter[${key}]`, Number(filter[key]));
				filterFormObject[`filter[${key}]`] = Number(filter[key]);
				filterObject.push(`${key}=${Number(filter[key])}`);
			}
			else if (columnType === "selectArray") {
				filterFormData.append(`filter[${key}]`, Number(filter[key]));
				filterFormObject[`filter[${key}]`] = Number(filter[key]);
				filterObject.push(`${key}=${Number(filter[key])}`);
			}
			else if (columnType === "sitis\\shop\\admin\\plugins\\TaggablePlugin") {
				filterFormData.append(`filter[${key}]`, filter[key]);
				filterFormObject[`filter[${key}]`] = Number(filter[key]);
				filterObject.push(`${key}=${Number(filter[key])}`);
			} else {
				filterFormData.append(key, filter[key]);
				filterFormObject[key] = Number(filter[key]);
				filterObject.push(`${key}=${Number(filter[key])}`);
			}
		})

		// Инициализация excpand
		let expand = Object.keys(visibleColumns)
			.map((key) => Boolean(!visibleColumns[key].omit && !!visibleColumns[key].expand) ? visibleColumns[key]?.nameField || "" : "")
			.filter((t) => !!t);
		expand.push('extraWebsites');
		expand = expand.join(',')
		if (!!expand) {
			filterFormData.append('expand', expand);
		}


		if (Object.keys(activePreset || {}).length > 0) {
			let listFilterPreset = filterToStringPreset(activePreset.filter);
			Object.keys(listFilterPreset || {}).map((key) => {
				filterFormData.append(`filter${key}`, JSON.stringify(listFilterPreset[key]));
			})
		}

		if (isObject) {
			return filterFormObject
		}

		window.history.replaceState(null, null, `/${pageName}?${filterObject.join("&")}`);
		localStorage.setItem('products-filter-object', filterObject.join("&"));

		return filterFormData
	}
	_getFilterBodyObject = ({isObject} = {}) => {
		const {activePreset} = this.props;
		const {filter, visibleColumns} = this.state;
		const activeFilterKeys = Object.keys({...filter}).filter((t) => filter[t]);

		// Инициализация тела запроса
		let filterFormObject = {};

		activeFilterKeys.map((key) => {
			const columnFullKey = key;
			let columnKey = Object.keys(visibleColumns).find((t) => Boolean(visibleColumns[t]?.nameField === key));
			if (!columnKey) {
				columnKey = Object.keys(visibleColumns).find((t) => Boolean(key.indexOf(visibleColumns[t]?.nameField) > -1))
			}

			const column = visibleColumns?.[columnKey] || {};
			const columnType = column?.type || "string";

			if ((key || '').indexOf('filter[') > -1) {
				key = key.replace("filter", "");
			}

			if (key === "sort" || key === "page" || key === "per-page") {}
			else if (columnFullKey === "filter[extraWebsites]") {
				if ((filter[columnFullKey] || []).length > 0) {
					lodash.set(filterFormObject, key, filter[columnFullKey])
				}
			}
			else if (column.group === "characteristic") {
				let filterItem = filter[key];
				if (typeof filterItem === "string") {
					filterItem = filterItem.split("[]")
				}
				filterItem.map((t) => {
					lodash.set(filterFormObject, `${key}`, t.value || t);
				})
			}
			else if (columnType === "text") {
				lodash.set(filterFormObject, `${key}.like`, filter[columnFullKey]);
			}
			else if (columnType === "number") {
				lodash.set(filterFormObject, `${key}`, Number.parseFloat(filter[columnFullKey]));
			}
			else if (columnType === "checkbox" || columnType === "toggleStatus") {
				lodash.set(filterFormObject, `${key}`, filter[columnFullKey]);
			}
			else if (columnType === "selectArray" || columnType === "sitis\\shop\\admin\\plugins\\TaggablePlugin") {
				lodash.set(filterFormObject, `${key}`, Number(filter[columnFullKey]));
			}
			else {
				lodash.set(filterFormObject, `${key}`, Number(filter[columnFullKey]));
			}
		})

		if (Object.keys(activePreset || {}).length > 0) {
			filterFormObject = {
				...filterFormObject,
				...activePreset.filter
			}
		}

		delete filterFormObject.preset;

		return filterFormObject
	}
	_getMergeFilterMultiChange = (initFilter) => {
		let filterBody = {...initFilter};

		let currentFilterPage = this._getFilterBodyObject();
		delete currentFilterPage.page;

		filterBody = lodash.merge(filterBody, currentFilterPage);
		filterBody = lodash.merge(filterBody, this._getFilterIdsMultiChange(filterBody));
		filterBody = lodash.merge(filterBody, {is_product_set: 0});

		return filterBody
	}

	// Навигация
	_routePageEdit = (id) => {
		this.props.history.push(`/products/edit/${id}`)
	}

	// Работа со множественным выбором
	onDelete = async (isConfirm) => {
		if (!isConfirm) {
			const {selected, pagination} = this.state;
			const totalCount = Boolean(selected.isAll) ? pagination.totalCount : selected.ids.length;
			const productLabel = convertorCaseWords(totalCount, [
				"товар",
				"товара",
				"товаров",
			])

			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите удалить ${totalCount} ${productLabel}?`,
				acceptButtonTitle: "Да, удалить",
				cancelButtonTitle: "Отменить",
				acceptButtonAction: this.onDelete.bind(this, true),
			})

			return
		}

		this.setState({isOpenBackdrop: true})


		this.setState({isOpenBackdrop: false})
	}
	onChangeAttributes = async (params, isConfirm) => {
		if (!isConfirm) {
			const {selected, pagination, visibleColumns} = this.state;
			const totalCount = Boolean(selected.isAll) ? pagination.totalCount : selected.ids.length;
			const productLabel = convertorCaseWords(totalCount, [
				"товар",
				"товара",
				"товаров",
			]);
			const paramsMessage = Object.keys(params).map((key) => {
				const columnKey = Object.keys(visibleColumns).find((t) => visibleColumns[t]?.nameField === key);
				const column = visibleColumns?.[columnKey] || {};

				return `${column.label}: ${params[key]}`
			})

			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите изменить следующие атрибуты для ${totalCount} ${productLabel}`,
				acceptButtonTitle: "Да, удалить",
				cancelButtonTitle: "Отменить",
				acceptButtonAction: this.onDelete.bind(this, true),
			})

			return
		}
	}

	// Вспомогательный фунции по управлению колонк таблицы
	_setVisibleColumns = async (initPageColumns) => {
		const {
			commonColumns,
			activePreset
		} = this.props;
		const localStorageVisibleColumns = JSON.parse(localStorage.getItem(`${pageName}-visible-columns`) || '[]');

		let visibleColumnKeys = [...commonColumns];
		if (activePreset) {
			visibleColumnKeys = [...visibleColumnKeys, ...(activePreset?.additional_columns || [])];
			visibleColumnKeys = (visibleColumnKeys || []).filter((t) => !(activePreset?.remove_columns || []).includes(t));
		}
		if (!!localStorageVisibleColumns && localStorageVisibleColumns.length > 0) {
			visibleColumnKeys = localStorageVisibleColumns.filter((t) => !t.omit).map((t) => t.nameField);
		}

		return [...initPageColumns].map((column) => {
			const isVisible = Boolean(visibleColumnKeys.includes(column.nameField));

			return {
				...column,
				omit: !isVisible
			}
		});
	}
	_setVisibleColumns__v1 = async (initPageColumns) => {
		const {commonColumns, activePreset} = this.props;
		let localStorageVisibleColumns = localStorage.getItem(`${pageName}-visible-columns`);
		if (!!localStorageVisibleColumns) {
			localStorageVisibleColumns = JSON.parse(localStorageVisibleColumns);

			if (typeof localStorageVisibleColumns === 'object' && localStorageVisibleColumns !== null && !Array.isArray(localStorageVisibleColumns)) {
				localStorageVisibleColumns = [];
				localStorage.removeItem(`${pageName}-visible-columns`)
			}
		}

		const mainColumns = commonColumns || [];
		const additionalColumns = activePreset?.additional_columns || [];
		const removeColumns = activePreset?.remove_columns || [];

		mainColumns.map((columnKey) => {
			if (initPageColumns.find((t) => t.nameField === columnKey)) {
				initPageColumns.find((t) => t.nameField === columnKey).omit = false;
			}
		})
		additionalColumns.map((columnKey) => {
			if (initPageColumns.find((t) => t.nameField === columnKey)) {
				initPageColumns.find((t) => t.nameField === columnKey).omit = false;
			}
		})
		removeColumns.map((columnKey) => {
			if (initPageColumns.find((t) => t.nameField === columnKey)) {
				initPageColumns.find((t) => t.nameField === columnKey).omit = true;
			}
		})

		let sortedColumns = [...mainColumns, ...additionalColumns];

		let result = [];
		sortedColumns.forEach(function (key) {
			var found = false;
			initPageColumns = initPageColumns.filter(function (item) {
				if (!found && item.nameField == key) {
					result.push(item);
					found = true;
					return false;
				} else
					return true;
			})
		});

		return [...result, ...initPageColumns].map((item) => {
			const findedItem = (localStorageVisibleColumns || []).find((t) => t.nameField === item.nameField);
			const omit = Boolean(typeof findedItem?.omit === "undefined") ? null : findedItem.omit;

			let initItem = {...item};
			initItem.hideFilter = Boolean(findedItem?.hideFilter);
			if (omit !== null && findedItem.isCustom) {
				initItem.omit = omit
			}

			return initItem
		})
	}
	_selectedRows = () => {
		const {ids, isAll} = this.state?.selected || {};
		const {count, totalCount, perPage} = this.state?.pagination || {};
		const paginationTotalCountNumber = Number.parseFloat(totalCount);

		let selectedCount = (ids || []).length;
		if (isAll) {
			selectedCount = paginationTotalCountNumber - (ids || []).length;
		}

		return selectedCount

		// let totalCountItems = (ids || []).length;
		// if (isAll && +count > 1) {
		// 	totalCountItems = totalCount - (perPage - (ids || []).length);
		// }
		// if (isAll && +count <= 1) {
		// 	totalCountItems = ids.length;
		// }
		//
		// return totalCountItems
	}
	_getInitBodyForMultiChange = () => {
		const {selected} = this.state;

		let filterBody = {}
		if (!selected.isAll) {
			filterBody = {
				filter: {
					id: selected.ids
				}
			}
		}
		if (!!selected.isAll) {
			const currentFilterPage = this._getFilterBodyObject();
			delete currentFilterPage.page;

			filterBody = {
				filter: objectsMerge(this._getFilterIdsMultiChange(currentFilterPage), currentFilterPage)
			}
		}

		if (Object.keys(filterBody?.filter?.not || {}).length > 1) {
			const filterBodyFilterNot = filterBody?.filter?.not || {};

			let and = [];
			Object.keys(filterBodyFilterNot).map((key) => {
				and.push({
					not: {
						[key]: filterBodyFilterNot[key]
					}
				})
			});

			filterBody.filter.and = and;

			delete filterBody.filter.not
		}

		return filterBody
	}
	_getFilterIdsMultiChange = (filterBody) => {
		const {ids, isAll} = this.state?.selected || {};

		let idsList = [...ids];
		if (isAll) {
			if (ids.length <= 0) {
				return {}
			}

			const countAndLength = (filterBody?.and || []).length;
			if (countAndLength <= 0) {
				return {
					and: {[countAndLength]: {not: { id: ids}}}
				}
			}

			return {
				and: [
					...(filterBody?.and || []),
					{not: { id: ids}}
				]
			}
		}

		return {
			id: idsList
		}
	}
	_apiUrlUpdateMultiProducts = () => {
		const selectedCount = this._selectedRows();
		const maxCount = process.env.REACT_APP_MAX_COUNT_PRODUCTS_FOR_HOT_UPDATE;
		if (!!maxCount && selectedCount > Number.parseFloat(maxCount)) {
			return '/api/admin/products/multiple-update-queue'
		}

		return '/api/admin/products/multiple-update'
	}

	render() {
		const {
			products,
			popularFilter,
			adminSharacteristics,

			filter,
			pagination,
			selected,
			visibleColumns,

			isOpenFilter,
			isLoadProducts,
			isOpenBackdrop
		} = this.state;
		const {
			classes,
			activePreset,
			adminLocked,
			userRoles
		} = this.props;

		return (
			<>

				<Box className={classes.head}>
					<Box className={classes.headLogo}>
						<MenuBookIcon sx={{color: "#0057FF", fontSize: 42}}/>
						<Typography variant="h1">Управление товарами</Typography>
					</Box>
					<Button
						variant="contained"
						startIcon={<AddBoxOutlinedIcon sx={{color: "white"}}/>}
						onClick={() => this.createProduct()}
					>
						Добавить товар
					</Button>
				</Box>

				<Box mb="20px">
					<Grid container justifyContent="space-between">
						<Grid item>
							<ProductsPresetsComponent disabled={isLoadProducts}/>
						</Grid>
						<Grid item>
							<Grid container spacing={1}>
								<Grid item>
									<Button
										variant="outlined"
										disabled={isLoadProducts}
										onClick={this._clearFilter}
									>
										Сбросить фильтр
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										disabled={isLoadProducts}
										onClick={this._requestSearch}
									>
										Применить поиск
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>

				<Box mb="20px">
					<ProductsPopularFiltersComponent
						data={filter}
						filter={filter}
						visibleColumns={visibleColumns}
						activePreset={activePreset}
						onChange={this._changeFilter}
						onSearch={this._requestSearch}
						onSearchFilter={this._requestSearchSetFilter}
					/>
				</Box>

				<Box mb="20px">
					<TablePaginationComponent
						filter={filter}
						pagination={pagination}

						onChangeFilter={this._changeFilterPagination}
					/>
				</Box>

				<Box className={classes.controls}>
					<ControlsComponent
						filter={filter}
						selected={selected}
						products={products}
						pagination={pagination}
						visibleColumns={visibleColumns}
						popularFilter={popularFilter}
						userRoles={userRoles}

						onSearch={this._requestSearch}
						onSearchFilter={this._requestSearchSetFilter}
						onChangeSelectedRows={this.changeSelectedRows}
						onChangeFilter={this._changeFilter}
						onChangePopularFilter={this._changePopularFilter}
						onChangeVisibleColumns={this._changeVisibleColumns}
						onResetFilter={this._clearFilter}

						onSetDiscount={this.setMultiDiscountProducts}
						onCancelDiscount={this.cancelMultiDiscountProducts.bind(this, false, false)}
						onChangePrice={this.changeMultiPriceProducts}
						onChangeMultiAttributes={this.changeMultiAttributesProducts}
						onDelete={this.onDelete}
						onDeleteMultiProducts={this.deleteMultiProducts}
						onChangeAttributes={this.onChangeAttributes}
						onResetDefaultSettings={this.resetDefaultSettings}
					/>
				</Box>

				<Box>
					<TableComponent
						data={products}
						filter={filter}
						selected={selected}
						pagination={pagination}
						visibleColumns={visibleColumns}
						adminLocked={adminLocked}

						isLoad={isLoadProducts}

						onChangeFilter={this._changeFilter}
						onChangePagination={this._changeFilterPagination}
						onChangeSelected={this.changeSelectedRows}
						onChangeVisibleColumns={this._changeVisibleColumns}

						routePageEdit={this._routePageEdit}
						duplicateProduct={this.duplicateProduct}
					/>
				</Box>


				<DialogConfirmation
					ref={this.refDialogConfirmation}
				/>

				<DialogCreateProductComponent
					ref={this.refDialogCreateProduct}
				/>

				<DialogMultiChangeProductsComponent
					ref={this.refDialogMultiChangeProducts}
					attributes={visibleColumns}
					adminSharacteristics={adminSharacteristics}
				/>

				<DialogDiscountSettingComponent
					ref={this.refDialogDiscountSetting}
				/>

				<DialogMultiChangePriceComponent
					ref={this.refDialogMultiChangePrice}
				/>

				<Backdrop open={isOpenBackdrop}>
					<CircularProgress sx={{color: "white"}}/>
				</Backdrop>
			</>
		);
	}
}

const styles = {
	head: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 16
	},
	headLogo: {
		display: "flex",
		alignItems: "center",

		"& h1": {
			marginLeft: 12
		}
	},

	controls: {
		marginBottom: 16
	}
}
const ProductsStyles = withStyles(styles)(Products);

export default ProductsStyles
