import React, {Component} from "react";
import {
	Box,
	Grid,
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	Client as ClientComponent,
	Products as ProductsComponent,
	Controls as ControlsComponent,
	Delivery as DeliveryComponent,
	AdminContent as AdminContentComponent
} from "./components";
import {
	 DialogConfirmation
} from "../../../components";
import {
	Notification,
	NotificationTypes
} from "../../../common/Notification";
import agent from "../../../agent/agent";
import BigNumber from "bignumber.js";
import {numberFormat} from "../../../common/Formater";

class OrderEdit extends Component {
	constructor(props) {
		super(props);

		const order = {...props?.location?.state?.initialOrder} || {}

		this.state = {
			order: order,
			orderItems: [],
			website_id: order?.website_id || null,

			isShowBackdrop: false,
			isLoading: true,
		};

		this.timeOutEventEdit = null;
		this.refDialogConfirmation = React.createRef();
	}

	componentDidMount = async () => {
		await this.getOrderItems();
		await this.eventEditOrderStart();
	}
	componentWillUnmount = async () => {
		await this.eventEditOrderEnd();
	}

	getOrderItems = async () => {
		const { order } = this.state;

		if (!order.id) {
			window.location.replace(`/orders/${this.props?.match?.params?.id}`)

			return
		}

		this.setState({isLoading: true})
		const response = await agent.get(`api/admin/orders/${order.id}/items?expand=product,product.productSetAllParentsCount&city_id=${order.user_city_id}&website_id=${order.website_id}`)
			.then(res => res.data)
			.catch(err => {
				return [];
			})
		this.setState({
			orderItems: [...response.map(item => {
				let discounts = item?.discounts || [];
				if (!discounts.find((t) => Boolean(String(t.type) === "6"))) {
					discounts.push({ name: "Оплата бонусами", value: 0, type: 6, percent: null })
				}
				if (!discounts.find((t) => Boolean(String(t.type) === "3"))) {
					discounts.push({ name: "Ручной промокод", value: 0, type: 3, percent: null })
				}

				return {
					...item,
					discounts,
					external_data: item.external_data !== null ? JSON.stringify(item.external_data) : item.external_data,
					quantity: String(item.quantity)
				}
			})],
			isLoading: false,
		})
	};

	changeOrder = (order) => {
		this.setState({ order })
	}
	changeProducts = (orderItems) => {
		this.setState({
			orderItems
		})
	}


	// Создание новой версии заказа
	createVersionOrder = async ({ isBonusesSkip } = {}) => {
		const errors = this.checkValidationOrder();
		if (!!errors) {
			Notification({
				message: errors,
				type: NotificationTypes.error
			})

			return
		}

		const isValidBonuses = this.checkBonusesOrder();
		if (isValidBonuses && isValidBonuses.isSkipped && !isBonusesSkip) {
			this.refDialogConfirmation.current.onOpen({
				message: isValidBonuses?.message,
				acceptButtonTitle: "Да",
				cancelButtonTitle: "Нет",
				acceptButtonAction: this.createVersionOrder.bind(this, { isBonusesSkip: true })
			});
			return
		}
		if (isValidBonuses && !isValidBonuses.isSkipped) {
			Notification({
				type: NotificationTypes.error,
				message: isValidBonuses.message
			})
			return
		}

		const { order, orderItems } = this.state;

		this.setState({ isShowBackdrop: true })

		const slug = `/api/admin/orders/${ order.id }/versions`;
		let body = {
			...order
		}
		body.customer_phone = (body.customer_phone || '').replace(/\D+/g,"");
		delete body.created_at;

		const newOrder = await agent.post(slug, body).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (newOrder.error) {
			this.setState({ isShowBackdrop: false })

			Notification({
				message: newOrder.error?.data?.message || "Ошибка сервера",
				type: NotificationTypes.error
			})

			return
		}


		let errorsAddedProducts = [];
		await Promise.all(orderItems.map(async (orderItem) => {
			const responseAdded = await this.addProductForNewVersionOrder(newOrder.id, orderItem, false);
			if (responseAdded.error) {
				errorsAddedProducts.push({
					product: orderItem,
					error: responseAdded.error?.data || {}
				})
			}
		}));
		if (errorsAddedProducts.length > 0) {
			this.setState({ isShowBackdrop: false })

			Notification({
				message: "При дублировании заказа возникла ошибка при дублировании товаров",
				type: NotificationTypes.error
			})

			return
		}

		Notification({
			type: NotificationTypes.success,
			message: "Заказ успешно изменен"
		})

		this.props.history.push(`/orders/${ newOrder.id }`)

		this.setState({ isShowBackdrop: false })

	}
	addProductForNewVersionOrder = async (orderId, orderItem) => {
		const slug = `/api/admin/orders/${ orderId }/items`;
		const formData = this.getProductToBody(orderItem);
		return await agent.post(slug, formData).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
	}
	getProductToBody = (orderItem) => {
		const discounts = [...(orderItem?.discounts || [])]
			.filter((t) => {
				if (Boolean(t?.type === 2)) {
					return false
				}
				if (!!orderItem?.newPrice && !["3", "6", "8"].includes(String(t?.type))) {
					return false
				}
				return true
			})
			.map((t) => {
				return {
					...t,
					value: Number.parseFloat(t?.value || '')
				}
			})
			.filter((t) => Boolean(t?.value || t?.percent))

		let formData = {
			"product_id": orderItem.product.id,
			"price": Number.parseFloat(orderItem.newPrice || orderItem.price),
			"cost": Number.parseFloat(orderItem.newPrice || orderItem.price),
			"quantity": Number(orderItem.quantity),
			"external_data": orderItem?.external_data || "{}"
		}
		if (orderItem.modification_id) {
			formData.modification_id = orderItem.modification_id;
		}
		if (orderItem.options) {
			let totalOptionsPrice = 0;
			formData.options = (orderItem.options || []).map((option) => {
				option.variants = (option.variants || []).map((variant) => {
					if (!variant.price) {
						variant.price = variant.price || 0;
					}

					delete variant.option_preset_disable_sync_prices;
					delete variant.option_preset_disable_sync_status;
					delete variant.option_preset_option_variant_id;
					delete variant.created_at;
					delete variant.updated_at;
					delete variant.additional_data;
					delete variant.grid_row_label;
					delete variant.group_id;
					delete variant.images;
					delete variant.status;
					delete variant.extraCharacteristicVariants;
					delete variant.extraExclusions;
					delete variant.supplier_price;
					delete variant.size_depth;
					delete variant.size_height;
					delete variant.size_length;
					delete variant.size_width;
					delete variant.price_save;

					totalOptionsPrice = totalOptionsPrice + Number.parseFloat(variant?.price);

					return variant
				});
				delete option.option_preset_option_group_id;
				delete option.created_at;
				delete option.grid_row_label;
				delete option.product_id;
				delete option.sort;
				delete option.updated_at;
				delete option.status;
				return option
			});
			formData.cost = formData.cost + totalOptionsPrice;
		}
		formData.cost = new BigNumber(formData.cost).multipliedBy(formData.quantity).toNumber();

		if (discounts.length) {
			formData.discounts = discounts;

			const discountValue6 = Number.parseFloat(discounts.find((t) => Boolean(String(t.type) === "6"))?.value || "0");
			if (discountValue6 > 0) {
				formData.cost = new BigNumber(formData.cost).minus(discountValue6).toNumber();
			}

			const discountValue3 = Number.parseFloat(discounts.find((t) => Boolean(String(t.type) === "3"))?.value || "0");
			if (discountValue3 > 0) {
				formData.cost = new BigNumber(formData.cost).minus(discountValue3).toNumber();
			}

			const discountValue8 = Number.parseFloat(discounts.find((t) => Boolean(String(t.type) === "8"))?.value || "0");
			if (discountValue8 > 0) {
				formData.cost = new BigNumber(formData.cost).minus(discountValue8).toNumber();
			}
		}

		return formData
	}

	checkValidationOrder = () => {
		const {
			order,
			orderItems,
		} = this.state;

		let errors = [];
		if (!order.user_city_id) {
			errors.push('Выберите "Город для региональных цен"')
		}

		[...(orderItems || [])].map((orderItem) => {
			const productBody = this.getProductToBody(orderItem);
			if (Number.parseFloat(productBody?.cost || '0') < 0) {
				errors.push(`Цена товара должна быть больше или равна нулю "${ orderItem?.product?.name }"`)
			}

			const externalData = JSON.parse(orderItem?.external_data || "{}");
			if (!externalData?.collection_id && orderItem?.product?.productSetAllParentsCount === 0) {
				errors.push(`Выберите коллекцию у товара "${ orderItem?.product?.name }"`)
			}
		});

		return errors.join("</br>")
	}
	checkBonusesOrder = () => {
		const { order, orderItems } = this.state;

		const usedBonuses = (order?.used_bonuses || 0);
		const totalBonuses = ((order?.user?.activeBonuses || 0) + usedBonuses);
		const currentUsedBonuses = (orderItems || []).reduce((value, product) => {
			const _value = Number.parseFloat([...(product?.discounts || [])]
				.find((t) => Boolean(String(t.type) === "6"))?.value || "0")
			return new BigNumber(_value).plus(value).toNumber()
		}, 0);

		if (currentUsedBonuses > totalBonuses) {
			return {
				message: "Использовано бонусов больше чем возможно.",
				isSkipped: false
			}
		}
		if (currentUsedBonuses !== usedBonuses) {
			return {
				message: "Сумма примененных бонусов прошлой и новой версии заказа отличается. Сохранить заказ?",
				isSkipped: true
			}
		}

		return null
	}


	eventEditOrderStart = async () => {
		clearTimeout(this.timeOutEventEdit);
		await agent.get(`/admin/api-shop-orders/${ this.state.order?.id }?ngrestCallType=update&fields=id`);
		this.timeOutEventEdit = setTimeout(async () => {
			await this.eventEditOrderStart();
		}, 5 * 1000);
	}
	eventEditOrderEnd = async () => {
		clearTimeout(this.timeOutEventEdit);
	}

	render() {
		const {
			order,
			orderItems,

			isShowBackdrop,
			isLoading
		} = this.state;

		return (
			<>

				<ControlsComponent
					orderId={order?.screen_id}
					onCreateVersion={this.createVersionOrder}
				/>

				<Box mt="10px"/>

				{Boolean(!isLoading) && (
					<>
						<Grid container spacing="10px">
							<Grid item xs={4}>
								<ClientComponent
									data={order}
									onChange={this.changeOrder}
								/>
							</Grid>
							<Grid item xs={4}>
								<DeliveryComponent
									data={order}
									products={orderItems}
									onChange={this.changeOrder}
								/>
							</Grid>
							<Grid item xs={4}>
								<AdminContentComponent
									data={order}
									onChange={this.changeOrder}
								/>
							</Grid>
						</Grid>

						<Box mt="10px"/>

						<ProductsComponent
							orderId={order.id}
							products={[...orderItems]}
							websiteId={order?.website_id}
							userCityId={order?.user_city_id}
							onChangeProducts={this.changeProducts}
						/>
					</>
				)}


				<Backdrop open={isShowBackdrop}>
					<CircularProgress color="secondary"/>
				</Backdrop>
				<DialogConfirmation ref={this.refDialogConfirmation}/>
			</>
		);
	}
}

export default OrderEdit
